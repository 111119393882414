import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home.vue"),
    meta: { template: "admin" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue"),
    meta: { template: "noside" },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/logout.vue"),
    meta: { template: "noside" },
  },
  {
    path: "/MyAccount",
    name: "MyAccount",
    component: () => import("../views/Account/MyAccount.vue"),
    meta: { template: "admin" },
  },
  {
    path: "/manage",
    name: "ManageHome",
    component: () => import("../views/Manage/ManageHome.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/timeshares",
    name: "Timeshares",
    component: () => import("../views/timeshares.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import("../views/Payment.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/blocks",
    name: "Blocks",
    component: () => import("../views/Manage/Blocks.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/groups",
    name: "Groups",
    component: () => import("../views/Manage/Groups.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/houses",
    name: "Houses",
    component: () => import("../views/Manage/Houses.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/houseTimeshares/:id",
    name: "HouseTimeshares",
    component: () => import("../views/Manage/HouseTimeshares.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/houseAttributes",
    name: "HouseAttributes",
    component: () => import("../views/Manage/HouseAttributes.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/houseTypes",
    name: "HouseTypes",
    component: () => import("../views/Manage/HouseTypes.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/seasons",
    name: "Seasons",
    component: () => import("../views/Manage/Seasons.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/weeks",
    name: "Weeks",
    component: () => import("../views/Manage/Weeks.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Manage/Users.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/prices",
    name: "Prices",
    component: () => import("../views/Manage/Prices.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/installments",
    name: "Installments",
    component: () => import("../views/Manage/Installments.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Manage/Settings.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/customers",
    name: "Customers",
    component: () => import("../views/Customer/List.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/preRequestList",
    name: "PreRequests",
    component: () => import("../views/PreRequests.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/preRequest",
    name: "PreRequest",
    component: () => import("../views/PreRequest.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/sales/:timeShareId",
    name: "Sales",
    component: () => import("../views/Sales.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/salesContract/:timeShareId/:customerId",
    name: "SalesContract",
    component: () => import("../views/SalesContract.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/departments",
    name: "Departments",
    component: () => import("../views/Departments.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/employees",
    name: "Employees",
    component: () => import("../views/Employees.vue"),
    meta: { template: "panel" },
  },
  // Report Start
  {
    path: "/report/collection",
    name: "ReportCollection",
    component: () => import("../views/Reports/Collection.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/report/delayedCollection",
    name: "ReportDelayedCollection",
    component: () => import("../views/Reports/DelayedCollection.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/report/salesList",
    name: "ReportSales",
    component: () => import("../views/Reports/Sales.vue"),
    meta: { template: "panel" },
  },
  {
    path: "/report/remainingDebit",
    name: "ReportRemainingDebit",
    component: () => import("../views/Reports/RemainingDebit.vue"),
    meta: { template: "panel" },
  },
  // Report End
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
